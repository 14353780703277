@import '../../../assets/scss/variables.scss';

.propertyTabs {
    // background-color: var(--color-white);
    border-top: 1px solid var(--color-border);

    .tabsButton {
        display: flex;
        gap: 10px;
        align-items: center;

        span {
            border-radius: 16px;
            border: 1px solid var(--color-border);
            background: var(--color-active);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2px 8px;
            align-items: center;
            @include round-image(24px);
            font-size: 12px;
            font-weight: 500;
            color: var(--color-button);
        }
    }

    :global {
        .MuiTabs-indicator {
            background: $gradient8;
        }
        .MuiTabs-root {
            border-bottom: 1px solid var(--color-border);
            position: sticky;
            background: var(--color-white);
            top: 148px;
            z-index:5;
            @include max-screen($tablet) {
                top: 48px;
            }
            .MuiTabs-scroller {
                max-width: 1920px;
                margin: 0 auto;
                padding: 0 60px;
                @include max-screen($desktop) {
                    padding: 0 35px;
                }
                @include max-screen($tablet) {
                    padding: 0 15px;
                }
            }

            .MuiTabs-flexContainer {
                width: 100%;
                justify-content: space-between;
                gap: 12px;

                .MuiButtonBase-root {
                    font-weight: 600;
                    font-size: 14px;
                    width: auto;
                    font-family: $font-family-base;
                    color: var(--color-gray);
                    letter-spacing: 0;
                    text-transform: capitalize;
                    padding: 0 4px;
                    white-space: nowrap;
                    width: 25%;
                    @include max-screen($desktop) {
                        width: auto;
                    }
                    &.Mui-selected {
                        color: var(--color-text);
                        span {
                        span {
                            color: var(--color-blue);
                            background-color: var(--color-blueLight);
                        }
                    }
                    }

                    .MuiTouchRipple-root {
                        display: none;
                    }
                }
            }
        }
    }

    &__content {
        background-color: var(--color-bgLight);
        border-radius: 0 0 10px 10px ;
    }

    &.propertyTabsTop {
       
        :global {
            .MuiTabs-flexContainer {
                justify-content: space-between;
            }
            .MuiButtonBase-root {
                &.Mui-disabled{
                    pointer-events: all !important;
                }
            }

            .MuiTabScrollButton-root.MuiTabScrollButton-horizontal {
                width: auto !important;
            }
        }

    }

    &__customLabel {
        font-weight: 600;
        font-size: 14px;
        font-family: $font-family-base;
        color: var(--color-gray);
        display: flex;
        align-items: center;
        gap: 8px;
        span{
            @include round-image(24px);
            background: linear-gradient($color-active, $color-active) padding-box, $gradient8 border-box;
        border: 1px solid transparent;
        color: var(--color-button);
        font-size: 12px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        }
    }
}