// colors
$color-primary:#005DA4;
$color-border: #EAECF0;
$color-borderDark: #D0D5DD;
$color-text: #101828;
$color-heading: #232D42;
$color-button:#344054;
$color-switch:#F2F4F7;
$color-bgLight: #FCFCFD;
$color-active:#F9FAFB;
$color-white:#fff;
$color-textLight:#475467;
$color-warning:#FEF0C7;
$color-warningText:#DC6803;
$color-warningBg:#FFFAEB;
$color-greenLight:#DCFAE6;
$color-green:#067647;
$color-green-light:#17B26A;
$color-greenBorder:#ABEFC6;
$color-greenBg:#ECFDF3;
$color-greenDark:#067647;
$color-badgeText:#363F72;
$color-badgeBg:#F8F9FC;
$color-blue:#14377D;
$color-blueLight:#EBF1FF;
$color-blueBorder:#D0E3FF;
$color-gray:#667085;
$color-black:#000;
$color-grayLight:#8A92A6;
$color-grayLight2:#F2F2F2;
$color-headerBg:#0C111D;
$color-links:#182230;
$color-loader:#528BFF;
$color-error:#D92D20;
$color-error-dark:#B42318;
$color-error-light:#FDA29B;
$color-errorBorder:#FEE4E2;
$color-errorBg:#FEF3F2;
$color-blueBright:#5925DC;
$color-blueBright2:#5E47FF;
$color-grayBg:#F4F6F9;
$color-blueDark:#030109;
$color-lightButton:#EFEFEF;
$color-success:#47CD89;
$color-orange:#F79009;
$color-blue-dark:#4E5BA6;
$color-red:#F04438;
$color-blue-light:#0BA5EC;
$color-blue-bright:#004EEB;
$color-magenta:#D444F1;
// base
$font-size-base: 16px;
$line-height-base: 1.5;
$font-family-base: "Inter", sans-serif;
$font-poppins: "Poppins", sans-serif;
$font-montserrat: "Montserrat", sans-serif;
$font-aeonic: "aeonik-trial-3", sans-serif;
$radius: 10px;



// breakpoints
$smobile: 575px;
$mobile: 767px;
$stablet: 991px;
$tablet: 1024px;
$desktop: 1199px;
$desktop-large: 1366px;
$desktop-xlarge: 1599px;

// image path
$imgPath: "/assets/images/";

$shadow:0px 1px 2px 0px rgba(16, 24, 40, 0.05);
$shadow2:0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
$shadow3:0px 10px 13px 0px rgba(17, 38, 146, 0.05);
$shadow4:0px 0px 0px 4px rgba(158, 119, 237, 0.24), 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
$shadow5:0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
$shadow6:0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);
$shadow7:0px 0px 0px 4px rgba(0, 102, 255, 0.24), 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
$shadow8:0px 0px 10px 0px rgb(16 24 40 / 10%);
$shadow9:0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
$shadow10:0px 1px 2px 0px rgba(16, 24, 40, 0.05);
$shadow11:0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
$gradient:radial-gradient(108.18% 108.18% at 50.22% 50.91%, #3401A1 0%, #693CE7 100%);
$gradientHover:radial-gradient(108.18% 108.18% at 50.22% 50.91%, #693CE7 0%, #3401A1 100%);
$gradient2:radial-gradient(152.44% 99.2% at 17.13% 33.61%, #2F0069 0%, #130144 27.95%, #000 100%);
$gradient3:linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 87.5%);
$gradient4:radial-gradient(66.54% 66.54% at 50% 100%, #2F0069 0%, #130144 27.95%, #000 100%);
$gradient5:radial-gradient(117.4% 82.85% at 82.33% 23.01%, #2F0069 0%, #130144 27.95%, #000 100%);
$gradient6: radial-gradient(113.9% 121.91% at 88.48% 88.49%, #2F0069 0%, #130144 27.95%, #000 100%);
$gradient7: radial-gradient(64.22% 64.22% at 50% 64.22%, #2F0069 0%, #130144 27.95%, #000 100%);
$gradient8:linear-gradient(90deg, rgba(114,237,242,1) 0%, rgba(81,81,229,1) 100%);
$gradient9:linear-gradient(135deg, #72EDF2 0%, #5151E5 100%);
// mixins
@import "mixins";


:root {
    --color-primary: #{$color-primary};
    --color-border: #{$color-border};
    --color-borderDark: #{$color-borderDark};
    --color-text: #{$color-text};
    --color-button: #{$color-button};
    --color-switch: #{$color-switch};
    --color-bgLight: #{$color-bgLight};
    --color-active: #{$color-active};
    --color-white: #{$color-white};
    --color-textLight: #{$color-textLight};
    --color-warning: #{$color-warning};
    --color-warningText: #{$color-warningText};
    --color-warningBg: #{$color-warningBg};
    --color-greenLight: #{$color-greenLight};
    --color-green: #{$color-green};
    --color-greenBg: #{$color-greenBg};
    --color-greenDark: #{$color-greenDark};
    --color-badgeText: #{$color-badgeText};
    --color-badgeBg: #{$color-badgeBg};
    --color-blue: #{$color-blue};
    --color-blueLight: #{$color-blueLight};
    --color-blueBorder: #{$color-blueBorder};
    --color-gray: #{$color-gray};
    --color-black: #{$color-black};
    --color-heading:#{$color-heading};
    --color-grayLight:#{$color-grayLight};
    --color-grayLight2:#{$color-grayLight2};
    --color-headerBg:#{$color-headerBg};
    --color-links:#{$color-links};
    --color-loader:#{$color-loader};
    --color-greenBorder:#{$color-greenBorder};
    --color-error:#{$color-error};
    --color-errorBorder:#{$color-errorBorder};
    --color-errorBg:#{$color-errorBg};
    --color-blueBright:#{$color-blueBright};
    --color-blueBright2:#{$color-blueBright2};
    --color-grayBg:#{$color-grayBg};
    --color-blueDark:#{$color-blueDark};
    --color-green-light:#{$color-green-light};
}
@media (prefers-color-scheme: dark) {
    :root {
       /*dark mode color variable*/
       

    }
}