@import '../../assets/scss/variables.scss';
.aiBoat{
    &__table{
        margin-top: 15px;
        :global{
            .custom-table .custom-table-wrapper {
                max-height: calc(100vh - 220px)!important;
            }
        }
    }
}