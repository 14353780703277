@import '../../assets/scss/variables.scss';
.tabs{
    :global {
        .MuiTabs-indicator {
            background: $gradient8;
        }

        .MuiTabs-root {
            .MuiTabs-scroller {
                padding: 9px 10px;
                width: 100%;

                @include max-screen($tablet) {
                    padding: 5px 0;
                }
            }

            .MuiTabs-flexContainer {
                gap: 12px;

                .MuiButtonBase-root {
                    font-weight: 600;
                    font-size: 14px;
                    min-height: 30px;
                    font-family: $font-family-base;
                    color: var(--color-gray);
                    letter-spacing: 0;
                    text-transform: capitalize;
                    padding: 0 30px;
                    white-space: nowrap;

                    &.Mui-selected {
                        color: var(--color-text);

                        span {
                            span {
                                color: var(--color-blue);
                                background-color: var(--color-blueLight);
                            }
                        }
                    }

                    .MuiTouchRipple-root {
                        display: none;
                    }

                }
            }
        }
    }
}