@import '../../assets/scss/variables.scss';
.follow{
&__Placeholder{
    width: 550px;
    @include max-screen($desktop) {
        width: 100%;
    }
    :global{
        .noData__button{
            @include max-screen($desktop) {
                flex-direction: column;
            }
        }
    }
}
&__calenderBtn{
    margin-top: 15px;
    gap: 15px;
}
&__calender{
    width: 100%;
    padding-top: 30px;
    position: relative;
    min-height: 300px;
    @include max-screen($desktop) {
        width: 100%;
    }
    :global{
        .rdrCalendarWrapper{
            background: linear-gradient($color-white, $color-white) padding-box, $gradient8 border-box;
            border: 1px solid transparent;
            box-shadow: $shadow11;
            border-radius: 6px;
            width: 100%;
            max-width: 504px;
            margin: 0 auto;
        }
    }
    &.blur{
        filter: blur(4px);
        pointer-events: none;
    }
}
&__note{
    width: calc(100% - 550px);
    @include max-screen($desktop) {
        width: 100%;
        margin-top: 30px;
    }
}
}