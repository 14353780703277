@import '../../assets/scss/variables.scss';
.list{
    &__filters{
        gap: 0 10px;
        margin-bottom: 15px;
    }
    &__table{
        :global{
            .custom-table .custom-table-wrapper {
                max-height: calc(100vh - 280px)!important;
            }
           .noData {
                min-height: calc(100vh - 312px) !important;
            }
        }
    }
}