.inputGroup {
    &:not(:last-child) {
        margin-bottom: 16px;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="date"],
    input[type="phone"],
    input[type="number"],
    select {
        border-radius: 8px;
        padding: 10px 14px;
        border: 1px solid var(--color-borderDark);
        background: var(--color-white);
        box-shadow: $shadow;
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        outline: 0;
        height: 44px;

        &::placeholder {
            color: var(--color-gray);
            opacity: 1;
        }

        &::-moz-placeholder {
            color: var(--color-gray);
            opacity: 1;
        }

        &:-moz-placeholder {
            color: var(--color-gray);
            opacity: 1;
        }

        &:-moz-placeholder {
            color: var(--color-gray);
            opacity: 1;
        }
    }
    input[type="password"]{
        &::-ms-reveal {
            display: none;
        }
    }
    .hint {
        margin-top: 6px;
        font-size: 14px;
        font-weight: 400;
        color: var(--color-txtLight);
    }

    &__input {
        position: relative;
        display: block;

        &.inputPassword {
            input {
                padding-right: 50px !important;
            }
        }

        .eyeIcon {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            height: 24px;
        }
        &.preIcon{
            input {
                padding-left: 45px !important;
            }
        }
        .preIconLeft{
            position: absolute;
            left: 15px;
            top: 50%;
            transform: translateY(-50%);
            color: var(--color-gray);
            height: 20px;
                svg{
                    height: 20px;
                    width: 20px;
                }
        }

        &.prefixMain {
            position: relative;

            input {
                padding-left: 48px !important;
            }

            .prefix {
                position: absolute;
                display: inline-flex;
                left: 15px;
                top: 50%;
                transform: translateY(-50%);
                height: 20px;
                color: var(--color-gray);

            }
        }
        &.postfixMain {
            position: relative;

            input {
                padding-right: 150px !important;
            }

            .postfix {
                position: absolute;
                display: inline-flex;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
                height: 20px;
                color: var(--color-button);
                font-size: 16px;
                font-weight: 400;
            }
        }
    }

.main-tooltip{
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    height: 19px;
    color: var(--color-error);
    opacity: 1;
    visibility: visible;
}
    &.inputPhone {
        input {
            border-radius: 8px;
            padding: 10px 14px;
            border: 1px solid var(--color-borderDark);
            background: var(--color-white);
            box-shadow: $shadow;
            width: 100%;
            font-size: 16px;
            font-weight: 400;
            outline: 0;
            height: 44px;

            &::placeholder {
                color: var(--color-gray);
                opacity: 1;
            }

            &::-moz-placeholder {
                color: var(--color-gray);
                opacity: 1;
            }

            &:-moz-placeholder {
                color: var(--color-gray);
                opacity: 1;
            }
        }
    }
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

}

.item-list {
    position: absolute;
    width: 100%;
    background-color: var(--color-white);
    max-height: 180px;
    overflow-y: auto;
    z-index: 9;
    box-shadow: $shadow3;

    li {
        padding: 5px 15px;
        font-size: 16px;
        color: var(--color-gray);
        gap: 10px;

        &:hover {
            background-color: var(--color-active);
        }
        &.highlighted {
            background-color: var(--color-active);
            color: var(--color-highlight-text);
          }
        small{
            font-size: 11px;
            text-align: left;
            font-weight: 500;
        }
    }
}

.inputSelect {
    &:not(:last-child) {
        margin-bottom: 16px;
    }

    .MuiInputBase-root {
        border-radius: 8px;
        padding: 0 14px !important;
        border: 1px solid var(--color-borderDark);
        background: var(--color-white);
        box-shadow: $shadow;
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        height: 44px;
        outline: 0;

        &::before,
        &::after {
            display: none;
        }

        .MuiInputBase-input {
            font-size: 16px;
            font-weight: 400;
            font-family: $font-family-base;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &::placeholder {
                color: var(--color-gray);
                opacity: 1;
            }

            &::-moz-placeholder {
                color: var(--color-gray);
                opacity: 1;
            }

            &:-moz-placeholder {
                color: var(--color-gray);
                opacity: 1;
            }
        }

        .MuiAutocomplete-endAdornment {
            display: none;
        }

        .selectArrow {
            display: flex;
            align-items: center;
            justify-content: center;
            pointer-events: none;
            cursor: pointer;

        }

        &.Mui-disabled {
            cursor: not-allowed;
            background-color: var(--color-border);

            .MuiInput-input {
                cursor: not-allowed;
            }
        }
    }

    .Mui-expanded {
        .selectArrow {
            transform: rotate(180deg);
        }
    }

    &.selectError {
        .MuiInputBase-root {
            border: 1px solid var(--color-warningText) !important;
        }
    }

    .hint {
        margin-top: 6px;
        font-size: 14px;
        font-weight: 400;
        color: var(--color-txtLight);
    }
    &.inputSmall{
        width: fit-content;
        .MuiInputBase-root{
            height: 22px;
            border: 1px solid var(--color-blueBorder);
            background-color: var(--color-blueLight);
            padding: 0 4px !important;
            &.Mui-disabled {
                cursor: not-allowed;
                border: 1px solid var(--color-borderDark);
                background-color: var(--color-border);
                .MuiInput-input{
                -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
                }
                .selectValueArrow{
                    // pointer-events: none;
                    // cursor:not-allowed
                    cursor: pointer;
                    .selectValue{
                        color: rgba(0, 0, 0, 0.38);
                    }
                }
            }
            .MuiInput-input{
                min-height: 0;
                padding: 0;
                font-size: 12px;
                color: var(--color-blue);
                -webkit-text-fill-color:var(--color-blue);
                display:none
            }
            .selectValueArrow{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
                height: 20px;
                cursor: pointer;
                .selectValue{
                    font-size: 12px;
                color: var(--color-blue);
                cursor: pointer;
                }
            }
            .selectArrow{
                width: 12px;
                height: 12px;
                color: var(--color-blue);
                cursor: pointer;
            }
        }
    }
}

.inputError {
    border: 1px solid var(--color-warningText) !important;
}

.error {
    font-size: 14px;
    color: var(--color-warningText) !important;
}

.optionList {
    gap: 10px;
    width: 100%;
&__records{
    gap: 8px;
}
    &__left {
        display: flex;
        align-items: center;
        gap: 10px;
        line-height: 1.4;
        font-weight: 500;
        color: var(--color-text);
        
        svg {

            width: 15px;
            height: 15px;
        }

        strong {
            color: var(--color-text);
        }
    }

    &__right {
        opacity: 0;

        .MuiAutocomplete-option[aria-selected="true"] &,
        .multiSelectItem[aria-selected="true"] & {
            opacity: 1;
        }
        .Mui-selected & {
            opacity: 1;
        }

        svg {
            color: var(--color-primary);
            width: 15px;
            height: 15px;
        }
    }

    .Mui-focused {
        .optionList__right {
            opacity: 1 !important;
        }
    }

}

.selectedOptions {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    padding-right: 5px;

    svg {

        width: 15px;
        height: 15px;
    }
}

.uploadFile {
    margin-bottom: 20px;

    &__input {
        border-radius: 12px;
        border: 1px dashed var(--color-border);
        background: var(--color-white);
        padding: 16px 24px;
        height: 126px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        input[type="file"] {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }

        figure {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            border: 1px solid var(--color-border);
            box-shadow: $shadow;
            margin: 0 auto 12px;
            width: 40px;
            height: 40px;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            color: var(--color-textLight);

            strong {
                color: var(--color-blue);
            }

            small {
                display: block;
                font-size: 12px;
                margin-top: 2px;
            }
        }
    }
}

.datePicker {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    &.datePickerDisabled{
        .react-datepicker-wrapper, .react-datepicker__input-container{
            background-color: var(--color-grayBg);
            cursor: not-allowed !important;
            border-radius: 8px;
            svg{
                opacity: 0.5;
            }
            input{
                background-color: var(--color-grayBg);
                cursor: not-allowed !important;
                &::placeholder{
                    color: var(--color-borderDark);
                }
            }
           
        }
        
    }

    .MuiFormLabel-root {
        top: -7px;
        left: 27px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
    }

    .MuiOutlinedInput-notchedOutline legend {
        display: none;
    }

    .MuiInputLabel-shrink {
        display: none;
    }

    .MuiInputBase-root {
        flex-direction: row-reverse;
        box-shadow: $shadow;
        border-radius: 8px;
        max-width: 190px;

        .MuiInputBase-input {
            padding: 8.5px 14px;
            color: var(--color-gray);
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
        }

        .MuiButtonBase-root {
            color: var(--color-white);
            &.clearButton{
                color: var(--color-gray);
                opacity: 1;
                position: absolute;
                right: 0;
            }
        }
    }

    .MuiOutlinedInput-notchedOutline {
        border: 1px solid var(--color-borderDark);
        top: 0;
    }

    .Mui-focused {
        .MuiOutlinedInput-notchedOutline {
            border: 1px solid var(--color-borderDark) !important;
        }
    }

    .Mui-error {
        .MuiOutlinedInput-notchedOutline {
            border: 1px solid var(--color-warningText) !important;
        }
    }
    .react-datepicker-wrapper{
        cursor:pointer;
        border: 1px solid var(--color-borderDark) !important;
        display: flex!important;
        justify-content: center!important;
        border-radius: 8px;
        .react-datepicker__input-container{
            cursor: pointer;
            display: flex!important;
            justify-content: center!important;
            align-items: center;
        }
    }
    .react-datepicker__input-container {
       
        input {
            padding: 10px 5px;
            border: 0;
            width: 120px;
            background: var(--color-white);
            box-shadow: $shadow;
            height: 38px;
            outline: none;
            font-size: 16px;
            font-weight: 500;
            cursor:pointer;
            font-family: $font-family-base;
            &.dateSelected{
                width: 210px; 
            }
    
            &::placeholder {
                color: var(--color-gray);
                opacity: 1;
                font-weight: 600;
            }
        }
    
      
    }
    .react-datepicker {
        border-radius: 4px;
        box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
        border: 0;
        &__close-icon{
            &::after{
                background-color: transparent !important;
                color: var(--color-gray)!important;
                font-size: 24px!important;
            }
        }
        &__header {
            background-color: #fff;
            border: 0;
            padding-top: 20px;
        }
        &__triangle {
            display: none;
        }
        .react-datepicker-popper {
            padding-top: 0 !important;
        }
        &__day-name, 
        &__day,
        &__time-name{
            width: 2.5rem;
            line-height: 2.5rem;
            font-family: "Roboto","Helvetica","Arial",sans-serif;
            font-size: 12px;
        }
        &__day {
            border-radius: 100%!important;
        }
        &__current-month {
            text-align: left;
            padding-left: 20px;
        }
        &__navigation--previous {
            left: auto;
            right: 40px;
            top: 20px;
        }
        &__navigation--next {
            top: 20px;
        }
        &__day-names {
            margin-top: 15px;
            color: rgba(0, 0, 0, 0.6);
        }
        
        &__day-name {
            color: rgba(0, 0, 0, 0.6);
        }
        
        .react-datepicker__day--keyboard-selected {
            border: 1px solid rgba(0, 0, 0, 0.6);
            background-color: #fff;
        }
    }
  
   
    .tableDatePicker &{
        margin: 0;
        position: relative;
        .clearButtonOther{
            position: absolute;
            right: 0;
            top: 1px;
            color: var(--color-gray);
            z-index: 1;
            cursor: pointer;
            @include round-image(18px);
            display: inline-flex;
            align-items: center;
            justify-content: center;
            &:hover{
                background-color: rgba(0, 0, 0, 0.04);
            }
            svg{
                width: 19px;
            }
           
        }
        .MuiFormLabel-root {
            top: -16px;
            left: 22px;
            font-size: 14px;
            font-style: normal;
            color: var(--color-textLight);
            font-weight: 400;
        }
        .MuiInputBase-root{
            box-shadow: 0 0 0;
            gap: 0;
            width: fit-content;
            max-width: 100%;
            padding-right: 0;
            cursor: pointer;

            .MuiInputBase-input {
                padding: 0;
                color: var(--color-textLight);
                font-weight: 400;
                width: 110px;
                height: 21px;
                &::placeholder{
                    color: var(--color-textLight);
                    opacity: 1;
                    padding-left: 5px;
                }
            }
           .MuiButtonBase-root{
                padding: 0;
                display: none;
                .MuiSvgIcon-root{
                    height: 20px;
                }
                &.clearButton{
                    display: inline-flex;
                }
            }
        }
    
        .MuiOutlinedInput-notchedOutline{
            padding: 0;
            border: 0 !important;
        }
    }
}

.label {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-button);
    display: block;
    margin-bottom: 6px;
}

.otp {
    &__form {
        display: flex;
        gap: 12px;
        margin-bottom: 6px;
        justify-content: center;
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

        input[type=number] {
        -moz-appearance: textfield;
        }
        input {
            display: flex;
            width: 280px;
            min-height: 40px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex-shrink: 0;
            border-radius: 10px;
            border: 2px solid var(--color-primary);
            background: var(--color-white);
            box-shadow: $shadow;
            font-size: 48px;
            font-weight: 500;
            color: var(--color-primary);
            text-align: center;
            letter-spacing: -0.96px;

            &:focus {
                box-shadow: $shadow4;
            }
        }
    }

    p {
        font-size: 14px;
        color: var(--color-textLight);

        span {
            text-decoration: underline;
        }
    }
}

.search {
    position: relative;

    &__icon {
        position: absolute;
        left: 10px;
        top: 50%;
        width: 20px;
        height: 20px;
        transform: translateY(-50%);
        color: var(--color-gray);
    }

    &__close {
        position: absolute;
        right: 10px;
        top: 50%;
        width: 24px;
        height: 24px;
        transform: translateY(-50%);
        color: var(--color-gray);
    }

    input {
        width: 100%;
        padding: 10px 14px 10px 35px;
        border-radius: 8px;
        border: 1px solid var(--color-borderDark);
        background: var(--color-white);
        box-shadow: $shadow;
        height: 44px;
        outline: none;
        font-size: 16px;
        font-weight: 400;

        &::placeholder {
            color: var(--color-gray);
            opacity: 1;
        }

        &::-moz-placeholder {
            color: var(--color-gray);
            opacity: 1;
        }

        &:-moz-placeholder {
            color: var(--color-gray);
            opacity: 1;
        }
    }

    &.focused {
        input {
            box-shadow: $shadow7;
        }
    }

    &.closeIcon {
        input {
            padding: 10px 70px 10px 14px !important;
        }

        &.searchIconRight {
            .search__icon {
                right: 40px;
            }
        }
    }

    &.searchIconRight {
        input {
            padding: 10px 35px 10px 14px;
        }

        .search__icon {
            left: auto;
            right: 10px;
        }
        .loader-circle {
            top: 67%;
        }
    }
}

input::-webkit-credentials-auto-fill-button,
input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    position: absolute;
    right: 0;
}

.multiSelect {
    &__placeholder{
        color: var(--color-gray);
    }
    &__placeholderText{
        color: var(--color-gray);
        font-weight: 400;
    }
    .MuiInputBase-root {
        background: var(--color-white);

        &.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
                box-shadow: $shadow7;
            }

        }

        .MuiSelect-select {
            padding: 9px 28px 9px 14px !important;
            font-size: 16px;
            font-weight: 500;
            color: var(--color-text);
            border-radius: 8px;
            font-family: $font-family-base;
            .multiSelectText &{
                font-weight: 400;
            }
        }

        .MuiOutlinedInput-notchedOutline {
            border: 1px solid var(--color-borderDark) !important;
            top: 0;
            border-radius: 8px;

            legend {
                display: none;
            }
        }

        .dropdownIcon {
            display: inline-flex;
            min-width: 34px;
            pointer-events: none;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 5px;

            &.opened {
                transform: rotate(180deg);
                transform-origin: center;
            }
        }
    }
    .multiselectValue{
        display: flex;
        padding-right: 5px;
        span{
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}

.multiSelectItem {
    padding: 10px !important;
    cursor: pointer;
    &:hover{
        background-color: rgba(25, 118, 210, 0.12) !important;
    }
    margin: 0 5px !important;

    .optionList__left {
        color: var(--color-text) !important;
        font-size: 16px !important;
        font-weight: 500 !important;
    }

    &.Mui-selected {
        background-color: var(--color-active) !important;
        &:hover{
            background-color: rgba(25, 118, 210, 0.12) !important;
        }
    }
    &.Mui-focusVisible{
        background-color: rgba(25, 118, 210, 0.12) !important;
    }
}

.multiSelectOverlay {
    .MuiList-root {
        max-height: 215px;
        overflow-y: auto;
    }
}


.MuiAutocomplete-paper {
    min-width: 160px;
}

.selectValueArrow{
    cursor: pointer;
}

