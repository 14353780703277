.custom-table {
    border-radius: 12px;
    background: var(--color-white);
    box-shadow: $shadow2;
    overflow: hidden;

    .custom-table-wrapper {
        max-height: calc(100vh - 200px);
        overflow-y: auto;

        @include max-screen($mobile) {
            max-height: 500px;
        }

        .calculator-table & {
            max-height: 350px;
        }

        .billingTable & {
            max-height: 500px;
        }

        table {
            width: 100%;


            tr {
                th {
                    padding: 12px 24px;
                    font-size: 12px;
                    font-weight: 500;
                    color: var(--color-textLight);
                    text-align: left;
                    font-family: $font-family-base;
                    white-space: nowrap;
                    cursor: grab;

                    @include max-screen($tablet) {
                        min-width: 100px !important;
                    }
                }

                td {
                    padding: 16px 24px;
                    font-size: 14px;
                    font-weight: 400;
                    color: var(--color-textLight);
                    font-family: $font-family-base;
                    text-align: left;
                    border-bottom: 1px solid var(--color-border);
                    height: 72px;

                    .calculator-table & {
                        padding: 10px 24px;
                        height: 40px;
                    }

                    .campaign-name {
                        font-weight: 600;
                        color: var(--color-primary);
                        font-size: 14px;
                        &.disable{
                            opacity: 0.5;
                            pointer-events: none;
                        &:hover{
                            text-decoration: none;
                        }
                        }
                    }

                    .campaign-name:hover {
                        text-decoration: underline;
                    }



                    .view {
                        font-size: 14px;
                        font-weight: 600;
                        color: var(--color-primary);
                        cursor: pointer;
                        text-decoration: none;
                    }

                    .titleDesc {
                        h2 {
                            font-size: 14px;
                            font-weight: 500;
                            color: var(--color-text);
                            text-transform: capitalize;
                            display: flex;
                            align-items: center;
                            gap: 5px;

                            input {
                                font-size: 14px;
                                font-weight: 500;
                                color: var(--color-text);
                                text-transform: capitalize;
                                background-color: transparent;
                                border: 1px solid transparent;
                                outline: none;
                                width: fit-content;

                                &:read-only {
                                    cursor: pointer;
                                }

                                &.activeInput {
                                    background-color: var(--color-switch);
                                    padding: 5px;
                                    border-radius: 4px;
                                    border: 1px solid var(--color-border);
                                }
                            }
                        }

                        p {
                            font-size: 14px;
                            color: var(--color-text);
                        }

                        h2 {
                            font-size: 14px;
                            font-weight: 600;
                            color: var(--color-primary);

                        }

                        span {
                            text-transform: capitalize;
                        }

                        &__blue {
                            font-size: 14px;
                            font-weight: 600;
                            color: var(--color-primary);

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }

                }

                &:hover {
                    background-color: var(--color-grayBg);
                }

                &.noDataRow {
                    td {
                        background-color: var(--color-white) !important;
                        border: 0;
                    }

                    &:hover {

                        background-color: var(--color-white) !important;
                        border: 0;

                        td {
                            background-color: var(--color-white) !important;
                        }
                    }

                    &:nth-child(odd) {
                        &:hover {
                            td {
                                background-color: var(--color-white) !important;
                            }
                        }
                    }
                }

                &.activity-highlight {
                    td {
                        background-color: var(--color-border) !important;

                    }

                    &:hover {
                        background-color: var(--color-border) !important;

                        td {
                            background-color: var(--color-border) !important;

                        }
                    }

                }
            }
        }
    }

    .infinite-scroll-component {
        overflow: inherit !important;
    }


    &.table-striped {
        table {
            tr {
                &:nth-child(odd) {
                    td {
                        background-color: var(--color-active);
                    }

                    &:hover {
                        td {
                            background-color: var(--color-grayBg) !important;
                        }
                    }
                }

                &.activity-highlight {
                    &:hover {
                        background-color: var(--color-border) !important;

                        td {
                            background-color: var(--color-border) !important;
                        }
                    }

                }
            }
        }
    }

    .table-shorting {
        gap: 6px;
    }

    .shortIcon {
        width: 16px;
        height: 16px;
        color: var(--color-textLight);

        svg {
            width: 16px;
            height: 16px;
        }
    }

    .inputSelect {
        width: 180px;
    }
    &.lastColSticky{
        table{
            tr{
                &:nth-child(odd) {
                    td {
                        &:last-child{
                        padding-left: 10px !important;
                        }
                        &:last-child{
                            span{
                                background-color: var(--color-active);
                            }
                        }
                       
                       
                    }
                }
                &:nth-child(even) {
                    td {
                        &:last-child{
                        background-color: var(--color-white);
                        padding-left: 10px!important;
                     
                        }
                       
                    }
                }
                td{
                    &:last-child{
                       &>span{
                            background-color: var(--color-white);
                            padding-right:24px;
                            padding-left:24px;
                        }
                        position: sticky;
                        right: 0;
                        padding: 0;
                    }
                }
                th{
                    &:last-child{
                        position: sticky;
                        right: 0;
                        padding: 0;
                    }
                }
                &:hover {
                    td {
                        background-color: var(--color-grayBg) !important;
                        &>span{
                            background-color: var(--color-grayBg) !important;
                        }
                    }
                }
            }
        }
    }
}

.disable-icon {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
}

.yellowDot,
.greenDot,
.redDot {
    width: 8px;
    height: 8px;
    display: inline-flex;
    background-color: $color-warning;
    border-radius: 100%;
    margin-left: 5px;
}

.greenDot {
    background-color: var(--color-green-light);
}

.redDot {
    background-color: var(--color-error);
}

.list-table {
    .titleDesc {
        cursor: default;
    }
}

.clampedText {
    @include max-screen ($tablet) {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        -webkit-box-orient: vertical;
    }
}

.tableCopy {
    gap: 10px;
    max-width: 260px;
}

.tableCopyRow {
    gap: 10px;
}

.phone-no {
    white-space: nowrap;

}

.bold-text {
    color: var(--color-text);
    font-weight: 500;
    font-size: 14px;
}

.copy-icon {
    width: 12px;
    height: 12px;
    color: var(--color-button);
    display: inline-flex;
}

.edit-save-icon {
    align-items: center;
    background-color: #fff;
    background-color: var(--color-white);
    border: 1px solid #d0d5dd;
    border: 1px solid var(--color-borderDark);
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, .06), 0 1px 3px 0 rgba(16, 24, 40, .1);
    display: flex;
    height: 25px;
    justify-content: center;
    min-width: 25px;
    width: 25px;

    svg {
        height: 12px;
        width: 12px;
    }
}

.chat-icon {
    position: relative;
    display: inline-flex;

    span {
        position: absolute;
        right: -4px;
        bottom: -2.5px;
        border-radius: 1000px;
        background: var(--color-primary);
        display: flex;
        padding: 6px 4px;
        justify-content: center;
        align-items: center;
        color: var(--color-white);
        font-family: Inter;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 1px;
    }
}

.tableIcon {
    svg {
        width: 16px;
        height: 16px;
        color: var(--color-textLight);

    }

    &.red {
        svg {
            color: var(--color-error);
        }
    }

    &.green {
        svg {
            color: var(--color-greenDark);
        }
    }

}
.noWrap{
    white-space: nowrap;
}
.multi-value {
    gap: 10px;
    max-width: 350px;
    span{
        word-break: break-word;
    }
}

.summaryText{
    ul{
        list-style-type: disc;
        padding-left: 20px;
    }
    ol{
        list-style-type:decimal;
        padding-left: 20px;
    }
}
.callStatus{
    gap: 10px;
    color: var(--color-textLight);
    figure{
        label{
            min-width: 60px;
        }
    }
    a{
        text-decoration: none;
        text-decoration: none;
        color: var(--color-textLight);
        min-width: 120px;
    }
    .statusDropdown{
        margin-bottom: 5px;
        .statusPill{
            background-color: var(--color-white);
            border-radius: 30px;
            cursor: not-allowed;
            border: 1px solid var(--color-borderDark);
            padding: 0 10px;
            height: 30px;
            font-weight: 500;
            font-size: 12px;
            color: var(--color-blue);
            display: inline-flex;
            align-items: center;
            min-width: 150px;
            justify-content: center;
            
        }
     
            .MuiInputBase-root.Mui-disabled{
                background-color: var(--color-white);
                border-radius: 30px;
                height: 30px;
                padding: 0 10px !important;
                justify-content: center;
                min-width: 125px;
            }
            .inputSelect {
                width: 150px;
                justify-content: center;
            }
            .selectValueArrow, .MuiInput-input{
                font-weight: 500!important;
                font-size: 12px!important;
                -webkit-text-fill-color: var(--color-blue) !important;
              text-align: center;
            }
            .selectValueArrow{
                display: none;
            }
        
    }

}
.multipleList{
    list-style: disc;
}
p.link-underline:hover{
    text-decoration: underline;
}
.log-attempts{
    .custom-table .custom-table-wrapper {
        max-height: calc(100vh - 124px)!important;
    }
   .noData {
        min-height: calc(100vh - 156px) !important;
    }
}