@import '../../assets/scss/variables.scss';
.leads{
    &__filters{
        gap: 0 10px;
        margin-bottom: 15px;
    }
    &__search{
        width: calc(100% - 135px);
        @include max-screen($mobile){
            width: 100%;
        }
    }
    &__btn{
        width: 125px;
        @include max-screen($mobile){
            width: 100%;
            margin-top: 15px;
        }
        :global{
            .filter-icon{
.button__text{
    &+.button__icon{
        position: absolute;
        left: 0;
        top: 2px;
        right: 0;
        margin: 0 auto;
        justify-content: center;
        transform: translateX(-27px);
    }
}
            }
        }
    }
    &__table{
        :global{
            .custom-table .custom-table-wrapper {
                max-height: calc(100vh - 232px)!important;
            }
           .noData {
                min-height: calc(100vh - 265px) !important;
            }
        }
    }
}