.button {
    display: flex;
    padding: 9px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    box-shadow: $shadow;
    font-weight: 600;
    font-size: 14px;
    color: var(--color-button);
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
    position: relative;
    font-family: $font-family-base;
    border: 0;
    min-height: 40px;


    &.xl {
        padding: 10.5px 18px;
        gap: 8px;
        height: 44px;
    }
&.rounded{
    border-radius: 55px;
}
    &.xxl {
        height: 60px;
        padding: 16px 22px;
        gap: 10px;
        font-size: 18px;

        @include max-screen($tablet) {
            padding: 10.5px 18px;
            gap: 8px;
            height: 44px;
            font-size: 14px;
        }
    }

    &.full {
        width: 100%;
    }

    &.icon {
        width: 36px;
        height: 36px;
        color: var(--color-button);
        padding: 0 5px;
        gap: 0;
    }
    &.icon-xl {
        width: 40px;
        height: 40px;
        color: var(--color-button);
        padding: 10px;
        gap: 0;
    }
    &.md {
        padding: 8px 12px;
        gap: 4px;
        height: 36px;
    }

    &.outline {
        border: 1px solid var(--color-borderDark);
        background: var(--color-white);

        &:hover,
        &.selcted-status {
            background-color: var(--color-switch);
        }

        &.focused {
            box-shadow: 0px 0px 0px 4px rgba(152, 162, 179, 0.14), 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }
    }
 
    &.gray {
        color: var(--color-gray);
    }
&.red-light{
    background-color: var(--color-errorBg);
        color: var(--color-error);
        border:1px solid var(--color-errorBorder);
}
&.green-light{
    background-color: var(--color-greenBg);
        color: var(--color-green);
        border:1px solid var(--color-greenBorder);
}
    &.blue {
        color: var(--color-blue);
    }
    &.white{
        background-color: var(--color-white);
        color: var(--color-blue);
        border:1px solid var(--color-border);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        &:hover{
            background-color: var(--color-switch);
        }
    }
    &.green {
        background: var(--color-green);
        color: var(--color-white);
    }
    &.red {
        background: var(--color-error);
        color: var(--color-white);
    }
    &.outline-red {
        color: $color-error-dark;
        border-color: $color-error-light;
        &:hover {
            background-color: lighten($color-error-light, 18%);
        }
        .MuiCircularProgress-root{
        color: $color-error-light !important;
        }
    }
    &.loader {

        &.scheduled:before,
        &.active:before,
        &.completed:before {
            opacity: 0;
        }
    }

    &.scheduled:before,
    &.active:before,
    &.completed:before {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: var(--color-warning);

    }

    &.active:before {
        background-color: var(--color-greenLight);
    }

    &.completed:before {
        background-color: var(--color-green);
    }
&.transparent{
    background-color: transparent;
    box-shadow: 0 0 0;
    border-color: transparent ;
    color: var(--color-black);
    font-size: 16px;
    padding: 0;
    &:hover{
        color: var(--color-button);
    }
}
&.gradient{
    border-radius: 100px;
    border: 0.5px solid rgba($color-white, 0.15);
    background: $gradient;
    box-shadow: 0px 0px 0px 0px #EA1B1B inset;
    height: 58px;
    padding: 17px 34px;
    letter-spacing: -0.18px;
    font-size: 18px;
    font-family: $font-aeonic;
    color: var(--color-white);
    font-weight: 400;
    &:hover{
        background:$gradientHover ;
    }
    &.gradientBlack{
        border-radius: 100px;
        border: 0.5px solid rgba(255, 255, 255, 0.15);
        background: radial-gradient(108.18% 108.18% at 50.22% 50.91%, #000 0%, #58585A 100%);
        box-shadow: 1px 0px 0px 0px #ffffff inset, -1px 0px 0px 0px #ffffff inset;
        &:hover{
            background: radial-gradient(108.18% 108.18% at 50.22% 50.91%, #58585A 0%, #000 100%);
        }
    }
    @include max-screen($mobile) {
        height: 56px; 
    }
}
&.black{
    background-color: var(--color-black);
    color: var(--color-white);
    font-size: 18px;
    padding: 12px 22px;
    height: 45px;
    letter-spacing: -0.18px;
    
    &:hover{
        background-color: var(--color-button);
    }
}
&.sm{
    svg{
        width: 16px;
        height: 16px;
    }
}
    &.disable {
        cursor: not-allowed !important;
        pointer-events: none;
        background-color: var(--color-switch) !important;
        color: var(--color-grayLight) !important;
        border: 1px solid var(--color-border);
    }
        &.dropdown{
            min-height: 44px;
            width: 100%;
            justify-content: space-between;
            font-size: 16px;
        font-weight: 500;
        color: var(--color-text);
        }
    &.primary {
        background-color: var(--color-primary);
        color: var(--color-white);

        &:hover {
            background-color: var(--color-blue);
        }
    }
        &.rotateIcon{
            .button__icon{
                transform: rotate(180deg);
            }
        }
    &__loader {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;

        .MuiCircularProgress-root {
            width: 18px !important;
            height: 18px !important;

        }
    }

    &__text,
    &__icon {
        display: inline-flex;

        svg {
            width: 20px;
            height: 20px;
        }

        .loader & {
            opacity: 0;
        }
    }
    &__text{
        span{
            font-weight: 400;
            padding-left: 4px;
        }
    }

    &.animated {
        position: relative;
        z-index: 1;
        overflow: hidden;

        &:after,
        &:before {
            content: "";
            width: 18px;
            height: 100%;
            position: absolute;
            background-color: rgba($color-white, 0.2);
            transform: skewX(-25deg);
            z-index: -1;
            left: 0;
            animation: animate 5s infinite;

        }

        &:before {
            margin-left: 24px;
            width: 10px;
        }
    }

    @keyframes animate {

        30%,
        100% {
            left: 0;
            left: 110%;
        }

        0% {
            left: 0;
        }

        50% {
            left: 110%;
        }
    }
}