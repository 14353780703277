.MuiCircularProgress-root {
    color: var(--color-loader) !important;

}

.loader-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-flex;
    .loadingBtn &{
      position: static;
      transform: none;
      display: inline-flex;
  }
}

.loader-wrapper {
    position: relative;
}
.loader-main{
    position: relative;
    min-height: calc(100vh - 120px);
}
.loader-main-large{
    position: relative;
    min-height: 400px;
}
.table-loader {
    position: relative;
    min-height: 80px;
}

.noData {
    text-align: center;
    padding:80px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 350px;
    margin: 0 auto;
    overflow: hidden;
    .MuiList-root &,.search-noresult-found &{
        padding:15px;
        max-width: 290px;
        figure {
            margin-bottom: 15px;
            svg{
                width: 30px;
                height: 30px;
            }
            &:before,
        &:after {
            width: 160px;
            height: 160px;
            top: -62px;
            left: -68px;
            background-size: 160px;
        }
        }
        figcaption{
            p{
                margin: 0;
            }
        }
    }
    &.smallSize {
        padding: 70px 0 0;
    }
    &.bgGray{
        figure {
            &:before,
            &:after {
                background-color: var(--color-active); 
            }
        } 
    }
  
     @include max-screen($mobile) {
        padding: 30px 0;
        min-height: calc(100vh - 450px);
     }
    figure {
        margin-bottom: 40px;
        position: relative;
        z-index: 0;

        &:before,
        &:after {
            content: "";
            width: 400px;
            height: 400px;
            display: block;
            position: absolute;
            top: -173px;
            left: -183px;
            z-index: 1;
            pointer-events: none;
            // background-color: var(--color-white);
          

        }

        &:after {
            // @include background-image(animated-circle, gif, center center, no-repeat, 400px);
            width: 400px;
            height: 400px;
            mix-blend-mode: difference;
            opacity: 5%;

        }

        svg {
            position: relative;
            z-index: 1;
            width: 40px;
            height: 40px;
        }
    }

    figcaption {
        position: relative;
        z-index: 1;

        h2 {
            font-weight: 600;
            margin-bottom: 4px;
            color: var(--color-text);
            font-size: 20px;
        }

        p {
            color: var(--color-textLight);
            font-size: 16px;
            margin-bottom: 32px;
        }
    }

    &__button {
        gap: 10px;
    }
    &.blared {
        h2 {
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            filter: blur(0px) !important;
        }

        figure {
            @include round-image(62px);
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: darken($color-active, 5%);
            margin-bottom: 10px;
            position: relative;

            &::after,
            &::before {
                background-color: transparent;
                background-image: none;
                border: 1px solid darken($color-active, 5%);
                @include round-image(62px);
                left: 0;
                top: 0;
            }

            &::before {
                animation: ripple 2.2s linear infinite;
            }

            &::after {
                animation: ripple 2.2s linear 1s infinite;
            }

            @keyframes ripple {
                0% {
                    transform: scale(1);
                }

                50% {
                    transform: scale(1.5);
                    opacity: 1;
                }

                100% {
                    transform: scale(2.5);
                    opacity: 0;
                }
            }

        }
    }
}
.pac-container{
    z-index: 99999999 !important;
}
.setting-alert{
    background-color: var(--color-blueLight);
    color: var(--color-blue);
    padding: 10px 15px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 6px;
    span{
      text-decoration: underline;
    }
}
.pillsDropdown{
    width: 100%;
    gap:15px;
    svg{
        width: 15px;
    }
}

.animated-svg{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    height: 20px;
    width: 20px;
    &.color-red{
        svg{
            color:var(--color-error);
            width: 20px !important;
        } 
        &::after, &::before{
            border: 2px solid var(--color-error);
        }
    }
    @include max-screen($tablet) {
     margin: 15px auto;
    }
     svg{
         color:var(--color-primary);
         width: 20px !important;
     }
     &::after, &::before{
         content: "";
         width: 28px;
         height: 28px;
         border: 2px solid var(--color-primary);
         border-radius: 24px;
         position: absolute;
         opacity: 0.3;
         left: -6px;
         top: -6px;
         animation: waves 2.5s linear 2.9s forwards;
         animation-delay: 0;
         animation-iteration-count: infinite;
         animation-direction: forward;
        
     }
     &::before{
         width: 40px;
         height: 40px;
         opacity: 0.1;
         left: -12px;
         top: -12px;
         animation: waves2 2.5s linear 2.9s forwards;
         animation-delay: 0;
         animation-iteration-count: infinite;
         animation-direction: forward;
     }
     @keyframes waves {
         0% {
           transform: scale(0);
           opacity: 0;
         }
         
         100% {
           transform: scale(1);
           opacity: 0.3;
         }
       }
       @keyframes waves2 {
         0% {
           transform: scale(0);
           opacity: 0;
         }
         
         100% {
           transform: scale(1);
           opacity: 0.1;
         }
       }
 }

 .mapControls{
  padding: 10px;
&__inner{
    border: 1px solid var(--color-borderDark);
    background: var(--color-white);
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    button{
        min-height: 40px;
padding: 12px 20px;
border: 0;

background: var(--color-white);
font-size: 14px;
font-weight: 600;
color: var(--color-button);
@include max-screen($mobile) {
    padding: 12px 8px;
    font-size: 12px;
 }
&:first-child{
    border-right: 1px solid var(--color-borderDark);
}
    }
}
   
 }
 .mapDrawBtn{
   padding: 10px 10px 10px 0;
   display: flex;
   align-items: center;
   gap: 10px;

   &.mapDrawBtnBg{
    min-width: 100vw;
    background-color: rgba($color-black, 0.5);
    justify-content: space-between;
    padding-bottom: 10px;
   
   p{
    color: var(--color-white);
    font-size: 16px;
    padding-left: 10px;
   }
   }
   .mapDrawBtnWrap{
    display: flex;
    gap: 10px;
   }
   .button{
    @include max-screen($mobile) {
        padding: 9px 8px;
        font-size: 12px;
     }
   }
 }
 .dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--color-white);
    color: var(--color-white);
    box-shadow: 9999px 0 0 -5px;
    animation: dot-pulse 1.5s infinite linear;
    animation-delay: 0.25s;
    &.dot-white{
      color: var(--color-black);
      &:before, &:after{
        color: var(--color-black);
      }
    }
  }
  .dot-pulse::before, .dot-pulse::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--color-white);
    color: var(--color-white);
  }
  .dot-pulse::before {
    box-shadow: 9984px 0 0 -5px;
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
  }
  .dot-pulse::after {
    box-shadow: 10014px 0 0 -5px;
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
  }
  
  @keyframes dot-pulse-before {
    0% {
      box-shadow: 9984px 0 0 -5px;
    }
    30% {
      box-shadow: 9984px 0 0 2px;
    }
    60%, 100% {
      box-shadow: 9984px 0 0 -5px;
    }
  }
  @keyframes dot-pulse {
    0% {
      box-shadow: 9999px 0 0 -5px;
    }
    30% {
      box-shadow: 9999px 0 0 2px;
    }
    60%, 100% {
      box-shadow: 9999px 0 0 -5px;
    }
  }
  @keyframes dot-pulse-after {
    0% {
      box-shadow: 10014px 0 0 -5px;
    }
    30% {
      box-shadow: 10014px 0 0 2px;
    }
    60%, 100% {
      box-shadow: 10014px 0 0 -5px;
    }
  }
  .map-main{
    position:relative;
  }
  .map-loader{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height:100%;
    top:0;
    left:0;
    background-color: rgba($color-black, 0.2);
    z-index: 99;
    &.loader-white{
      background-color: rgba($color-white, 0.5);
    }
  }

    .time-pill{
      display: inline-flex;
      align-items: center;
      margin-left: 8px;
        border-radius: 16px;
    border: 1px solid var(--color-border);
    background: var(--color-active);
    display: flex;
    padding: 2px 8px;
    align-items: center;
    color: var(--color-button);
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    
    }
.close-circle{
    @include round-image(24px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--color-error);
    border: 1px solid var(--color-error);
    svg{
        width: 18px;
        height: 18px;
    }
}
.close-circle-main{
    @include round-image(29px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.stripe-icon{
    color: var(--color-white);
    width: 26px;
    height: 26px;
    border-radius: 4px;
    background-color: var(--color-primary);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    svg{
        width: 18px;
        height: 18px;
    }
    &:hover{
        opacity: 0.8;
    }
}

.image-loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  img {
      filter: blur(5px);
  }

  &__loader {
      position: absolute;
      left: 50%;
      top: 50%;
      margin: 0 auto;
      transform: translate(-50%, -50%);
  }
}

.wave-animation {
  @include round-image(52px);
  position: relative;
  display: block;

  &::after,
  &::before {
      content: "";
      background-color: transparent;
      background-image: none;
      border: 1px solid darken($color-border, 5%);
      @include round-image(52px);
      left: 0;
      top: 0;
      display: block;
      position: absolute;
  }

  &::before {
      animation: ripple 2.2s linear infinite;
  }

  &::after {
      animation: ripple 2.2s linear 1s infinite;
  }

  @keyframes ripple {
      0% {
          transform: scale(1);
      }

      50% {
          transform: scale(1.5);
          opacity: 1;
      }

      100% {
          transform: scale(2.5);
          opacity: 0;
      }
  }
}