@import '../../assets/scss/variables.scss';
.main{
    &__table{
        margin-top: 20px;
        :global{
            .custom-table .custom-table-wrapper{
                max-height: calc(100vh - 262px);
            }
        }
    }
    &__total{
        font-size: 16px;
        color: var(--color-textLight);
        span{
            font-weight: bold;
            font-size: 18px;
            color: var(--color-primary);
            padding: 2px 4px;
            border-radius: 4px;
            margin-left: 5px;
        }
    }
}