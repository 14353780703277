.MuiSwitch-root {
    width: 36px !important;
    height: 20px !important;
    padding: 0 !important;
    border-radius: 12px;

    .MuiButtonBase-root {
        top: 2px !important;
        left: 3px !important;
        padding: 0 !important;

        &.Mui-checked {
            color: var(--color-white);
            transform: translateX(14px);

            &+.MuiSwitch-track {
                background-color: var(--color-primary) !important;
                opacity: 1 !important;
            }
        }
    }

    .MuiSwitch-thumb {
        width: 16px !important;
        height: 16px !important;
    }

    .MuiSwitch-track {
        background: var(--color-switch) !important;
        opacity: 1 !important;
    }
}


.MuiFormControlLabel-root {
    .MuiCheckbox-root {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 5px;
        min-width: 24px;

        .MuiSvgIcon-root {
            width: 16px;
            height: 16px;
            color: var(--color-primary);
            border: 1px solid var(--color-borderDark);
            box-shadow: $shadow;
            border-radius: 4px;

            path {
                display: none;
            }
        }

        &.Mui-checked {
            .MuiSvgIcon-root {
                width: 16px;
                height: 16px;
                color: var(--color-primary);
                border: 0;
                border-radius: 0px;
                transform: scale(1.1);

                path {
                    display: block;
                }
            }
        }

    }

    .MuiFormControlLabel-label {
        font-size: 14px;
        color: var(--color-button);
        font-weight: 500;
        font-family: $font-family-base;
    }
}

.MuiDrawer-root {
    .MuiBackdrop-root {
        background-color: rgba($color-headerBg, 0.6) !important;
    }
}

.MuiSlider-root {
    height: 8px !important;
    border-radius: 4px !important;
    color: var(--color-primary) !important;

    .MuiSlider-valueLabel {
        box-shadow: $shadow5;
        font-size: 12px;
        color: var(--color-button);
        border-radius: 8px;
        border: 1px solid var(--color-border);
        background: var(--color-white);
        padding: 8px 12px;
        font-weight: 600;
        font-family: $font-family-base;

        &::before {
            display: none;
        }
    }

    .MuiSlider-thumb {
        width: 24px;
        height: 24px;
        border-radius: 12px;
        border: 1.5px solid var(--color-primary);
        background: var(--color-white);

    }
}

.error-tooltip {
    .MuiTooltip-tooltip {
        background-color: var(--color-error);
        margin-bottom: 10px !important;

    }

    .MuiTooltip-arrow {
        color: var(--color-error);
    }
}

.speedometer {
    .current-value {
        display: none;
    }
}

.MuiAutocomplete-listbox {
    @include max-screen($mobile) {
        max-height: 150px !important;
    }
}

.Mui-focusVisible {
    background-color: rgba(0, 0, 0, 0.12);
}

.multiSelectItem {
    cursor: pointer;

    &:hover {
        background-color: var(--color-active) !important;
    }

}

.multiSelectItem[aria-selected="true"] {
    background-color: var(--color-active) !important;

    .optionList__right {
        opacity: 1;
    }
}

.multiselectScroll {
    position: relative;

    label {
        font-size: 14px;
        font-weight: 500;
        color: var(--color-button);
        display: block;
        margin-bottom: 6px;
    }

    &__input {
        width: 100%;
        border-radius: 8px;
        width: 100%;
        padding: 5px 14px;
        border: 1px solid var(--color-borderDark);
        background: var(--color-white);
        box-shadow: $shadow;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        max-height: 100px;
        overflow-y: auto;

        input {
            border: 0;
            font-size: 16px;
            font-weight: 400;
            outline: 0;
            min-width: 30px;
            flex-grow: 1;
            padding: 5px;

            &::placeholder {
                color: var(--color-gray);
                opacity: 1;
            }

            &::-moz-placeholder {
                color: var(--color-gray);
                opacity: 1;
            }

            &:-moz-placeholder {
                color: var(--color-gray);
                opacity: 1;
            }

            &:-moz-placeholder {
                color: var(--color-gray);
                opacity: 1;
            }
        }

    }

    &__tag {
        display: flex;
        align-items: center;
        margin: 2px;
        background-color: var(--color-switch);
        padding: 2px 6px;
        border-radius: 4px;

        svg {
            margin-left: 6px;
            cursor: pointer;
            width: 16px;
        }
    }

    ul {
        width: 100%;
        margin: 2px 0 0;
        padding: 5px;
        position: absolute;
        list-style: none;
        overflow: auto;
        max-height: 150px;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgb(0 0 0 / 0.15);
        z-index: 5;
        background-color: var(--color-white);
        &>li {
            padding: 5px 12px;
            display: flex;
            cursor: pointer;
            justify-content: space-between;
            &:hover{
                background-color: rgba(25, 118, 210, 0.12) !important;
            }
            svg {
                color: var(--color-primary);
            }

           &.selectedItem {
                background-color: var(--color-active) !important;

                &:hover {
                    background-color: rgba(25, 118, 210, 0.12) !important;
                }
            }

        }
    }
    .noData {
        padding: 10px 0;
        figure{
            margin: 0;
        }
        figcaption{
            p{
                margin: 0;
            }
        }
    }
}