@import '../../assets/scss/variables.scss';
.hero {
    &__inner {
        padding: 0 0 15px;
         width: 100%;
         margin: 0 auto;
         @include max-screen($mobile) {
            padding: 0 0 5px;
        }
        h1 {
            font-size: 24px;
            font-weight: 600;
            line-height: 1.2;
            margin-bottom: 4px;
            color: var(--color-text);
            @include max-screen($tablet) {
                font-size: 22px;
             }
             @include max-screen($mobile) {
                font-size: 18px;
             }
             span {
                color: var(--color-grayLight);
             }
        }

        p {
            font-size: 16px;
            color: var(--color-textLight);
            display: flex;
            align-items: center;
            gap: 5px;
            &.campaigns{
                color: var(--color-grayLight);
            }
          
        }
        .heroInfo{
            gap: 12px;
        }
        .heroButton{
            gap: 12px;
        }
    }
}